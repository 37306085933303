import React from "react";
import "./grammarPages/Post.css";
import GrammarContent from "../pages/GrammarContent";

export default function Grammar() {
  return (
    <div className="container">
      <div className="wrapper">
        <section className="main">
          <GrammarContent />
        </section>
        <aside className="sidebar">
          <h3>About</h3>
          <ul>
            <li>
              If you have questions, comments or suggestions, please write to
              us:{" "}
            </li>
            <li className="email">
              <a href="mailto:anelmad2019@gmail.com">Send an email</a>
            </li>
          </ul>
        </aside>
      </div>

      <footer>
        <h3>Anelmad: grammar</h3>
        <p>Learn more about English grammar</p>
      </footer>
    </div>
  );
}
