import React from "react";
import "./Post.css";
import Aker from "./green_aker.svg";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

export default function StealAndRob() {
  return (
    <div className="container">
      <div className="wrapper">
        <section className="main">
          <div className="card">
            <div className="cardTitle">ROB, STEAL, BURGLE</div>
          </div>
          <hr />
          <p style={{ paddingTop: "20px" }}>
            Ad nessexdem amyag <span style={{ color: "#c12d30" }}>to rob</span>{" "}
            ma yella yiwen yuker bnadem nniḍen, neγ yuker amkan (am lbanka).{" "}
          </p>

          <div className="highlight">
            <div className="highligtedText">
              He <span style={{ color: "#ff79c6" }}>robbed</span> a bank : yuker
              lbanka
              <br />
              They <span style={{ color: "#ff79c6" }}>robbed</span> him :
              ukrentt
            </div>
          </div>

          <p>
            Deg umedya-agi, ur nessexdam ara{" "}
            <span style={{ color: "#c12d30" }}>
              <i>to steal</i>
            </span>
            . Acuγer? acku ma nenna-d{" "}
            <span style={{ color: "#c12d30" }}>
              <i>he stole the bank</i>
            </span>{" "}
            anamek-is am akken amakur yeddem lbanka-nni yewwi-tt yid-s!{" "}
          </p>
          <p>
            Dacu d lferq yellan tura gar{" "}
            <span style={{ color: "#c12d30" }}>to rob</span> akked{" "}
            <span style={{ color: "#c12d30" }}>to steal</span>? Amyag{" "}
            <span style={{ color: "#c12d30" }}>to steal</span> yettmaga ma yiwen
            yuker taγawsa, yeddem-itt yewwi-tt yid-s:
          </p>

          <div className="highlight">
            <div className="highligtedText">
              He <span style={{ color: "#ff79c6" }}>stole</span> my wallet:
              yuker-iyi tatteẓdamt-iw (yewwi yid-s tatteẓdamt-nni)
              <br />
              Her <span style={{ color: "#ff79c6" }}>car was stolen</span> :
              ukren-as takeṛṛust-is (ulac takeṛṛust-nni, wwin-tt) : ukrentt
            </div>
          </div>
          <p>
            Taglizit γur-s daγen amyag{" "}
            <span style={{ color: "#c12d30" }}>to burgle</span> : neqqar-it i
            takerḍa n wexxam kan:
          </p>

          <div className="highlight">
            <div className="highligtedText">
              Their house was <span style={{ color: "#ff79c6" }}>burgled</span>:
              ttwakren / kecmen-asen s axxam, ukren-ten
            </div>
          </div>

          <div>
            <h3 style={{ padding: "20px" }}>
              Yiwen n wemyag s teqbaylit &rarr; 3 s teglizit:
            </h3>
            <div style={{ marginBottom: "10%" }}>
              <img className="media" src={Aker} />
            </div>
          </div>
        </section>
        <aside className="sidebar">
          <div style={{ paddingBottom: "60px" }}>
            <h3>Recent Posts</h3>
            <ul>
              <li>
                <p style={{ fontWeight: "bold" }}>
                  to rob, to steal, to burgle
                </p>
              </li>
            </ul>
          </div>

          <hr />

          <Link to="/grammar">
            <Button
              size="small"
              color="primary"
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              Grammar Page
            </Button>
          </Link>
        </aside>
      </div>

      <footer>
        <h3>Anelmad</h3>
        <p>Hope you enjoyed this lesson!</p>
      </footer>
    </div>
  );
}
