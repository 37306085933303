import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  card: {
    maxWidth: "100%",
  },
});

export default function Content() {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" className={classes.blogsContainer}>
      <Typography variant="h4" className={classes.blogTitle}>
        ANELMAD
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card className={classes.card}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image="/images/sinziana-susa-SNHsMunOPME-unsplash.jpg"
                title="Open book"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  English-Kabyle Dictionary
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  <ul>
                    <li>
                      a new dictionary designed to meet the needs of Kabyle
                      speakers
                    </li>
                    <li>
                      includes more than 1900 English words and hundreds of
                      Kabyle words.
                    </li>
                  </ul>
                </Typography>
              </CardContent>
              <CardActions></CardActions>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card className={classes.card}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image="/images/joshua-hoehne-1UDjq8s8cy0-unsplash.jpg"
                title="Open book"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Asegzawal Taqbaylit-Taglizit
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  <ul>
                    <li>
                      asegzawal amaynut,ad iԑiwen iqbayliyen yebγan ad ilemden
                      Taglizit.
                    </li>
                    <li>
                      Taẓrigt-a n usegzawal llan deg-s 1900 n wawalen n teglizit
                    </li>
                  </ul>
                </Typography>
              </CardContent>
              <CardActions></CardActions>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card className={classes.card}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image="/images/sinziana-susa-SNHsMunOPME-unsplash.jpg"
                title="Open book"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Grammar and Vocabulary
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Verbs, Nouns, Adjectives Learn new words with Anelmad!
                </Typography>
              </CardContent>
              <CardActions>
                <Link to="/grammar">
                  <Button size="small" color="primary">
                    GRAMMAR
                  </Button>
                </Link>
              </CardActions>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card className={classes.card}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image="/images/gmail.png"
                title="Open book"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Arut-aγ-d
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  ma tesԑam iseqsiyen Arut-aγ-d ar: anelmad2019@gmail.com
                </Typography>
              </CardContent>
              <CardActions>
                <li className="email">
                  <a href="mailto:anelmad2019@gmail.com">Send an email</a>
                </li>
              </CardActions>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
