import React from "react";
import ResAppbar from "./components/layout/ResAppbar";
import { Router, Route, Switch } from "react-router-dom";
import Grammar from "./components/grammar/Grammar";
import Main from "./components/Main";
import History from "./components/layout/History";
import GrammarCard from "./components/pages/GrammarCard";
import StealAndRob from "./components/grammar/grammarPages/StealAndRob";
import ReactGA from "react-ga";
import EveryAndEach from "./components/grammar/grammarPages/EveryAndEach";

//Google Analytics
ReactGA.initialize("UA-139312644-1");
ReactGA.pageview("/");

export default function App() {
  return (
    <Router history={History}>
      <React.Fragment>
        <ResAppbar />
        <Switch>
          <Route exact path="/" component={Main} />
          {/*<Route path="/home" component={LandingPage} />*/}
          <Route path="/grammar" component={Grammar} />
          <Route path="/grammarcard/info/:id" children={<GrammarCard />} />
          {/*<Route path="/search" component={Search} /> */}
          <Route path="/steal_rob" component={StealAndRob} />
          <Route path="/every" component={EveryAndEach} />
        </Switch>
      </React.Fragment>
    </Router>
  );
}
