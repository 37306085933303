import React, { useState } from "react";
import axios from "axios";
import { Grid } from "@material-ui/core";
import Search from "./Search";
import Words from "./Words";
import WordItem from "./WordItem";
import LandingPage from "./pages/LandingPage";

const Main = (props) => {
  const [words, setWords] = useState([]);
  const [word, setWord] = useState("");
  const [optionValue, setOptionValue] = useState("");
  const [currentScreen, setCurrentScreen] = useState("LandingPage");
  const handleSubmit = async (searchTerm, optionValue) => {
    await axios
      .get("/api/lexemes", {
        params: {
          searchTerm: searchTerm,
          optionValue: optionValue,
        },
      })
      .then(
        (res) => {
          console.log("Word Item: " + res.data);
          setWords(res.data);
          setCurrentScreen("WordItem");
          /*this.setState({
            words: res.data,
            currentScreen: "WordItem",
          });*/

          console.log("The word returned: " + word);

          //this.setState({ lexemes: res.data, key: Date.now() });
        },
        (error) => {
          alert("handleSubmit error " + error);
          setCurrentScreen("LandingPage");
        }
      );
  };

  console.log(words);
  if (currentScreen == "LandingPage") {
    return (
      <Grid justify="center" container spacing={10}>
        <Grid item xs={12}>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Search onFormSubmit={handleSubmit} />
            </Grid>
            <Grid item xs={12}>
              <LandingPage />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  } else if (currentScreen == "WordItem") {
    return (
      <Grid justify="center" container spacing={10}>
        <Grid item xs={12}>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Search onFormSubmit={handleSubmit} />
            </Grid>
            <Grid item xs={12}>
              <Words words={words} />
            </Grid>
            <Grid item xs={12}>
              <WordItem word={word} synonym={handleSubmit} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

export default Main;
