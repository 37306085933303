import React, { useState, useEffect, useRef } from "react";
import { Paper, FormControl } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "./Button";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import History from "./layout/History";
import { useLocation } from "react-router-dom";
import { FaSearch } from "react-icons/fa";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    flex: 3,
    width: 400,
  },

  iconButton: {
    padding: 10,
  },
  formControl: {
    height: 28,
    margin: 4,
  },

  searchStyle: {
    padding: 0,
  },

  inputText: {
    padding: "10px",
    fontSize: "17px",
    border: "1px solid grey",
    float: "left",
    width: "80%",
    background: "#f1f1f1",
  },
  submitButton: {
    /*background: "transparent",
  border: "none",
  outline: "none",
  marginLeft: "-33px"*/
    float: "left",
    width: "20%",
    padding: "10px",
    background: "#2196F3",
    color: "white",
    fontSize: "17px",
    border: "1px solid grey",
    borderLeft: "none",
    cursor: "pointer",
  },
});
var data = [
  "abrid",
  "acebḥan",
  "acekkuḥ",
  "acenfir",
  "acennay",
  "addar",
  "adebbuz",
  "adfel",
  "adlis",
  "adrar",
  "afenğal",
  "aferṭeṭṭu",
  "afus",
  "agad",
  "agecrir",
  "ageffur",
  "agersal",
  "aggad",
  "aggur",
  "agtul",
  "agu",
  "ajeğğig",
  "akal",
  "akli",
  "allaγ",
  "allen",
  "aman",
  "amcic",
  "amegṛeḍ",
  "amellal",
  "ameẓẓuγ",
  "amqeṛquṛ",
  "amzur",
  "anebdu",
  "anezgum",
  "aniwa",
  "anyir",
  "anzaren",
  "anṣuf",
  "anẓaṛ",
  "aqcic",
  "aqejjiṛ",
  "aqemmuc",
  "aqjun",
  "argaz",
  "aru",
  "aruy",
  "asebter",
  "aseggas",
  "asekkil",
  "aseklu",
  "asem",
  "asif",
  "ass",
  "asγaṛ",
  "atmaten",
  "awal",
  "axxam",
  "ayaziḍ",
  "ayetma",
  "ayyur",
  "azagur",
  "azekka",
  "azemmur",
  "azezzu",
  "azger",
  "azrem",
  "azul",
  "azzel",
  "aγ",
  "aγenjuṛ",
  "aγerbaz",
  "aγeṛda",
  "aγyul",
  "aγṛum",
  "aԑebbuḍ",
  "aԑekkaz",
  "aԑrur",
  "aԑudiw",
  "aḍad",
  "aḍar",
  "aḍebsi",
  "aḍil",
  "aḥeckul",
  "aḥelluf",
  "aṣerγu",
  "baba",
  "babaγayu",
  "baṭaṭa",
  "bibb",
  "bururu",
  "claγem",
  "ccna",
  "cnu",
  "dagi",
  "dder",
  "ddu",
  "dduṛt",
  "demmer",
  "dukkel",
  "eks",
  "eḍs",
  "enγ",
  "ečč",
  "fransa",
  "frari",
  "gen",
  "ibrik",
  "iccer",
  "idamen",
  "iddew",
  "idmaren",
  "ifelfel",
  "ifirelles",
  "ifker",
  "iflew",
  "igenni",
  "ilef",
  "iles",
  "imawlan",
  "imeṭṭawen",
  "imi",
  "imlul",
  "inisi",
  "isli",
  "itbir",
  "itri",
  "iwleẓẓi",
  "izem",
  "izi",
  "izwiγ",
  "iγess",
  "iγil",
  "iḍ",
  "iḍelli",
  "iṭij",
  "kemm",
  "kečč",
  "lattay",
  "lbunya",
  "lemri",
  "lexrif",
  "lkaɣeḍ",
  "lmus",
  "lqaṛes",
  "lzzayer",
  "lṭufan",
  "marikan",
  "mass",
  "neggez",
  "nekk",
  "nnal",
  "qeyyel",
  "rwel",
  "seksu",
  "seddu",
  "seḍs",
  "settut",
  "sew",
  "skeṛ",
  "snitra",
  "ssuden",
  "suḍ",
  "tabbuct",
  "tabburt",
  "tabexsist",
  "tadist",
  "taḍsa",
  "tafat",
  "tafsut",
  "tafunast",
  "tagiṭart",
  "tagrest",
  "tagrurt",
  "tajeğğigt",
  "takeṛṛust",
  "tala",
  "tamart",
  "tamessaḍt",
  "tameγra",
  "tameṭṭut",
  "tanemmirt",
  "taneqlet",
  "tasa",
  "tasarut",
  "tasedda",
  "tawacult",
  "tawenza",
  "tayet",
  "tayri",
  "taγaṭ",
  "taγenjawt",
  "taԑebbuḍt",
  "taṣabunt",
  "ticraḍ",
  "tidukkla",
  "tifdent",
  "tifirest",
  "tigzirt",
  "tilelli",
  "tili",
  "tilivizyu",
  "timesrifegt",
  "timest",
  "timiṭ",
  "tiqenṭeṛt",
  "tisegnit",
  "tissist",
  "tixsi",
  "tiziri",
  "tizizwit",
  "tiγirdemt",
  "tiγmeṛt",
  "tiẓurin",
  "ttejṛa",
  "tura",
  "tuγmest",
  "ṭumaṭic",
  "uccen",
  "udem",
  "ul",
  "wa",
  "wihin",
  "yemma",
  "yessetma",
  "zegzew",
  "zger",
  "zṛudiyya",
  "ččina",
  "γenni",
  "γeṛ",
  "γli",
  "ԑeyyeḍ",
  "ṭasi",
  "ṭubba",
  "ṭṭaq",
  "ṭṭes",
  "ṭṭeḍ",
  "ẓẓeṛb",
];

const Search = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [optionValue, setOptionValue] = useState("English-Kabyle");
  const [autoList, setAutoList] = useState(data);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const inputRef = useRef();

  const handleOptionChange = (event) => {
    //this.setState({ optionValue: event.target.value });
    setOptionValue(event.target.value);
  };
  const handleChange = (e) => {
    //this.setState({ searchTerm: e.target.value });
    setSearchTerm(e.target.value);
    if (!e.target.value) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  /*const onChange = (e) => {
    setValue(e.target.value);
  };*/

  const handleLatinButton = (letter) => {
    // alert(e);

    inputRef.current.focus();
    console.log("input ref before useEffect: " + inputRef.current);
    //this.setState({ latinBtn: e.target.value });
    //this.setState({ searchTerm: this.state.searchTerm + letter });
    setSearchTerm(searchTerm + letter);
  };
  // console.log("searchterm " + searchTerm);

  const handleSubmit = (e) => {
    e.preventDefault();

    History.push("/?q=" + searchTerm);
    props.onFormSubmit(searchTerm, optionValue);
    //setSearchTerm("");
    inputRef.current.blur();
    setOpen(false);
    inputRef.current.focus();
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const q = params.get("q");
    //console.log("q query: " + q);

    setSearchTerm(q);
    inputRef.current.focus();
  }, []);
  /*componentDidMount() {
    this.nameInput.focus();
  }
  componentDidUpdate() {
    this.nameInput.focus();
  }*/
  const classes = useStyles();
  return (
    <Container maxWidth="lg" style={{ marginTop: "55px" }}>
      <Paper elevation={6} style={{ padding: "25px", marginTop: "25px" }}>
        <Button letter="č" handleLatinButton={handleLatinButton} />
        <Button letter="ḍ" handleLatinButton={handleLatinButton} />
        <Button letter="ğ" handleLatinButton={handleLatinButton} />
        <Button letter="ԑ" handleLatinButton={handleLatinButton} />
        <Button letter="ḥ" handleLatinButton={handleLatinButton} />
        <Button letter="γ" handleLatinButton={handleLatinButton} />
        <Button letter="ṛ" handleLatinButton={handleLatinButton} />
        <Button letter="ṣ" handleLatinButton={handleLatinButton} />
        <Button letter="ṭ" handleLatinButton={handleLatinButton} />
        <Button letter="ẓ" handleLatinButton={handleLatinButton} />

        <form onSubmit={handleSubmit}>
          <input
            className={classes.inputText}
            type="text"
            list="optionsList"
            value={searchTerm}
            onChange={handleChange}
            ref={inputRef}
            placeholder="Aru awal dagi  ...  Enter your word here"
          />
          <datalist id="optionsList">
            {open == true && optionValue == "Taqbaylit-Taglizit"
              ? autoList.map((o) => <option key={o}>{o}</option>)
              : null}
          </datalist>
          <button className={classes.submitButton} type="submit">
            <FaSearch />
          </button>
        </form>
        <FormControl component="fieldset">
          <FormLabel component="legend">Language</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={optionValue}
            onChange={handleOptionChange}
          >
            <FormControlLabel
              value="English-Kabyle"
              control={<Radio />}
              label="English-Kabyle"
            />
            <FormControlLabel
              value="Taqbaylit-Taglizit"
              control={<Radio />}
              label="Taqbaylit-Taglizit"
            />
          </RadioGroup>
        </FormControl>
      </Paper>
    </Container>
  );
};

export default Search;
