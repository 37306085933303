import React, {useState} from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({

  /* second example */
columnLayout: {
  height: "100vh",
  backgroundColor: "#f8f8f8",
  margin: "40px auto 0 auto",
  lineHeight: "1.65",
  padding: "20px 40px",
  [theme.breakpoints.up(750)]: {
    display: "flex",
    justifyContent:"space-between",                  // secondary
  }
},

mainColumn :{
  flex: "0 0 66.666667%",
    flexGrow: "0",
    flexShrink: "0",
    flexBasis: "66.6667%",
maxWidth: "80%",
backgroundColor: "white",
  lineHeight: "1.8",
fontSize: "18px",
color: "#212529",
fontWeight: "400",
fontFamily: "-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
position: "relative",
width: "100%",
paddingRight: "15px",
paddingLeft: "15px",
display: "block",
},

sidebarOne: {
  flex: "1",
  order: "1",
  flexBasis: "10%",
  lineHeight: "1.8",
fontSize: "18px",
color: "#212529",
fontWeight: "400",

},

sidebarTwo: {
  flex: "1",
  order: "3",
  flexBasis: "10%",
  lineHeight: "1.8",
fontSize: "18px",
color: "#212529",
fontWeight: "400",
},
    
   
    
    
    


  /*columnLayout :{
    regular:{
      maxWidth: "1800px",
  backgroundColor: "#FFF",
  margin: "40px auto 0 auto",
  lineHeight: "1.65",
  padding: "20px 40px",
      
     
       
    
    '@media (min-width: 900px)': {
     
      display: "flex",
      justifyContent:"space-between",
    }

    }
    
  },*/

  
  
    
  }));

export default function EveryAndEach() {
    const classes = useStyles();
    return (
     
      <div className={classes.columnLayout}>
      <div>
      
         <Card style={{padding: "20px"}}>
          
        
            Dacu d lferq gar to rob akked
            to steal
        
            
         
             
             
            
              Ad nessexdem Amyag to rob ma
              yella yiwen yuker bnadem nniḍen, neγ yuker amkan (am lbanka).
            
               
                
              
                <i>
                  He robbed a bank
                </i>
                : yuker lbanka
                <br />
                <i>
                  they robbed him
                </i>
                : ukrentt
            
              
                Deg umedya-agi, ur neqqar ara <i>he stole the bank</i>. Acuγer?
                acku anamek-is am akken amakur yeddem lbanka-nni yewwi-tt yid-s!
              
             
                Dacu d lferq yellan tura gar rob akked d steal? <br />
                Amyag to steal yettmaga ma
                yiwen yuker taγawsa, yeddem-itt yewwi-tt yid-s:
              
             
                
                
                <i>
                  He stole my wallet
                </i>
                : yuker-iyi tatteẓdamt-iw (yewwi yid-s tatteẓdamt-nni)
                <br />
                <i>
                  Her car was stolen
                </i>
                : ukren-as takeṛṛust-is  (ulac takeṛṛust-nni, wwin-tt)
            
            
                Tagnizit γur-s daγen amyag
                to burgle: qqarentt i
                takerḍa n wexxam kan:
             
                 
                  <i>
                    Their house was burgled
                  </i>
                  : ttwakren / kecmen-asen s axxam, ukrent-ent
                  <br />
              
              
           
        
                  </Card>
         
       
      
           <Link to="/">
             <Button
               size="small"
               color="primary"
               style={{
                 textTransform: "uppercase",
                 fontWeight: "bold",
               }}
             >
               Dictionary
             </Button>
           </Link>
          
       
           <Link to="/grammar">
             <Button
               size="small"
               color="primary"
               style={{
                 textTransform: "uppercase",
                 fontWeight: "bold",
               }}
             >
               Grammar Page
             </Button>
           </Link>
         
        

         
      
        
       
        
      
      </div>
    
      
    
      <div className={classes.sidebarTwo}>
        <h3>Sidebar Two</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum modi nisi tenetur sint dignissimos nulla, blanditiis nesciunt.</p>
      </div>
    </div>


      
      
    
    )
}
