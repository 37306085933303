import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  blogsContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "16px",
  },
  blogTitle: {
    fontWeight: 800,
    paddingBottom: "16px",
  },
  card: {
    marginBottom: "1.5rem !important",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "break-word",
    backgroundColor: "#fff",
    backgroundClip: "border-box",
    border: "1px solid rgba(0,0,0,.125)",
    borderRadius: ".5rem",
    padding: "1.25rem",
  },

  cardTitle: {
    marginBottom: ".75rem",
    fontSize: "2rem",
    lineHeight: "1.2",
    color: "#212529",
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  buttonLearnMore: {
    color: "#fff",
    backgroundColor: "#007bff",
    border: "1px solid transparent",
    borderRadius: ".25rem",
    padding: ".375rem .75rem",
    textAlign: "center",
    verticalAlign: "middle",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "1rem",
    lineHeight: "1.5",
    fontWeight: "400",
    margin: "4px 2px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#318CE7" /* Green */,
      color: "white",
    },
  },
});

export default function GrammarContent() {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" className={classes.blogsContainer}>
      <Typography variant="h4" className={classes.blogTitle}>
        Articles
      </Typography>
      <Grid container spacing={3}>
        {/*<Grid item xs={12}>
          <Card className={classes.card}>
            <div className={classes.cardTitle}>
              Present continous vs present simple
            </div>
            <p>
              Dacu i d lferq gar "present continous" akked "present simple"?
            </p>
            <CardActions>
              <Link to="/steal">
                <button className={classes.buttonLearnMore}>Learn More</button>
              </Link>
            </CardActions>
          </Card>
  </Grid>*/}
        <Grid item xs={12}>
          <Card className={classes.card}>
            <div className={classes.cardTitle}>To rob, to steal, to burgle</div>
            <p>
              Amek ad nessexdem imyagen{" "}
              <span style={{ color: "#c12d30" }}>to rob</span>,{" "}
              <span style={{ color: "#c12d30" }}>to steal</span> akked{" "}
              <span style={{ color: "#c12d30" }}>to burgle</span>
            </p>
            <CardActions>
              <Link to="/steal_rob">
                <button className={classes.buttonLearnMore}>Learn More</button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
