import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useParams, Link } from "react-router-dom";

const GRAMMARCARDS = [
  {
    id: 0,
    title: "Lferq gar to rob akked to steal",
    explanation:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Suscipit error temporibus optio? Fugiat, voluptates? Quisquam deserunt dolores quasi, aliquid voluptas, impedit et sapiente eligendi nihil sit illum necessitatibus excepturi rem?",
    example1: "Yuker lbanka",
    example2: "Lorem ipsum dolor",
  },
  {
    id: 1,
    title: "Modal verbs in english",
    explanation:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Suscipit error temporibus optio? Fugiat, voluptates? Quisquam deserunt dolores quasi, aliquid voluptas, impedit et sapiente eligendi nihil sit illum necessitatibus excepturi rem?",
    example1: "Yuker lbanka",
    example2: "Lorem ipsum dolor",
  },
  {
    id: 2,
    title: "Adjectives in Berber",
    explanation:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Suscipit error temporibus optio? Fugiat, voluptates? Quisquam deserunt dolores quasi, aliquid voluptas, impedit et sapiente eligendi nihil sit illum necessitatibus excepturi rem?",
    example1: "Yuker lbanka",
    example2: "Lorem ipsum dolor",
  },
];

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function GrammarCard() {
  let { id } = useParams();
  let grCard = GRAMMARCARDS[parseInt(id, 3)];
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="/images/brit.png"
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            <h4>{grCard.title}</h4>
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {grCard.explanation}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Link to="/">
          <Button size="small" color="primary">
            Dictionary
          </Button>
        </Link>
        <Link to="/grammar">
          <Button size="small" color="primary">
            Grammar Page
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
}
