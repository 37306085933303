import React, { useState } from "react";
import { Grid, Paper, Typography, Container, Card } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";

import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
  },
  media: {
    height: 140,
  },

  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  cardStyle: {
    padding: 10,
  },
  orthStyle: {
    fontWeight: "bold",
    fontSize: 30,
  },
  spanStyle: {
    display: "block",
    padding: 8,
  },

  quoteStyle: {
    color: "#0096ab",
    fontSize: 20,
    fontWeight: "bold",
  },
  transExampleStyle: {
    color: "#0096ab",
    fontSize: 20,
  },

  gramInfoStyle: {
    background: "#e5ebf3",
    padding: "0 25px",
    margin: "0 -15px 1em -15px",
  },

  idiomHeadStyle: {
    content: "",
    marginRight: "10px",
    fontWeight: "bold",
    border: "solid blue",
    borderWidth: "0 3px 3px 0",
    display: "inline-block",
    padding: "3px",
    transform: "rotate(-45deg)",
    webkitTransform: "rotate(-45deg)",
  },

  expressionsStyle: {
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "#234b9a",
    fontFamily: "Zilla Slab",
    fontSize: "1.3rem",
  },

  posTagStyle: {
    fontSize: "0.7rem",
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "#000",
    fontFamily: "Zilla Slab",
  },
  PartOfSpeechStyle: {
    fontSize: 18,
  },

  examplesStyles: {
    fontSize: 20,
    padding: "0",
  },
  transExampleStyle: {
    color: "#c12d30",
    fontWeight: "bold",
    fontSize: 20,
  },
}));

const WordItem = ({ word }) => {
  //console.log("From WordItem component: " + word.orth);
  const classes = useStyles();
  return (
    <Container className={classes.content}>
      <Card className={classes.root}>
        <Grid item xs={12}>
          <Paper className={classes.cardStyle}>
            <Typography className={classes.gramInfoStyle}>
              {word.orth == "notFoundWordDisplayUlacAwal" ? (
                <span className={classes.orthStyle}>
                  <p style={{ fontSize: "19px", padding: "0", margin: "0" }}>
                    This word does not exist in the dictionary{" "}
                  </p>
                  <p style={{ fontSize: "19px", padding: "0", margin: "0" }}>
                    OR
                  </p>
                  <p style={{ fontSize: "19px", padding: "0", margin: "0" }}>
                    you did not select the right option between{" "}
                    <i style={{ color: "blue" }}>"English-Kabyle"</i> and
                    <i style={{ color: "blue" }}>"Taqbaylit-Tagnizit"</i>
                  </p>
                  <p style={{ margin: "60px" }}></p>
                  <p style={{ fontSize: "19px", padding: "0", margin: "0" }}>
                    Ulac awal-agi deg usegzawal
                  </p>
                  <p style={{ fontSize: "19px", padding: "0", margin: "0" }}>
                    neγ
                  </p>
                  <p style={{ fontSize: "19px", padding: "0", margin: "0" }}>
                    ur textareḍ ara akken i d-yewwi gar{" "}
                    <i style={{ color: "blue" }}>"English-Kabyle"</i> d{" "}
                    <i style={{ color: "blue" }}>"Taqbaylit-Tagnizit"</i>
                  </p>
                </span>
              ) : (
                <Typography className={classes.gramInfoStyle}>
                  {word && word.asuddimIsem != null ? (
                    <span className={classes.orthStyle}>
                      {word.asuddimIsem}
                    </span>
                  ) : (
                    <p className={classes.orthStyle}>{word.orth} </p>
                  )}{" "}
                  {word &&
                    word.gramGrp.map((i) => (
                      <p>
                        <span className={classes.posTagStyle}> {i.pos} </span>
                        {word.pron != null ? (
                          <span>{word.pron}</span>
                        ) : null}{" "}
                        {i.pt != null ? (
                          <span>
                            ({" "}
                            <i className={classes.PartOfSpeechStyle}>
                              past tense
                            </i>{" "}
                            <strong className={classes.PartOfSpeechStyle}>
                              {i.pt}
                            </strong>
                          </span>
                        ) : (
                          <span>
                            {i.talgha != null ? (
                              <span>
                                <i>(</i>{" "}
                                <strong className={classes.PartOfSpeechStyle}>
                                  {i.talgha}
                                </strong>
                                )
                              </span>
                            ) : null}
                          </span>
                        )}
                        {i.pp != null ? (
                          <span>
                            {" "}
                            <i className={classes.PartOfSpeechStyle}>
                              past participle
                            </i>{" "}
                            <strong className={classes.PartOfSpeechStyle}>
                              {i.pp}
                            </strong>
                            )
                          </span>
                        ) : null}
                        {i.fem != null ? (
                          <span>
                            {" "}
                            ( <i className={classes.PartOfSpeechStyle}>
                              unti
                            </i>{" "}
                            <strong className={classes.PartOfSpeechStyle}>
                              {i.fem}
                            </strong>
                          </span>
                        ) : null}
                        {i.pl != null ? (
                          <span>
                            {" "}
                            (<i className={classes.PartOfSpeechStyle}>
                              asget
                            </i>{" "}
                            <strong className={classes.PartOfSpeechStyle}>
                              {i.pl}
                            </strong>
                            )
                          </span>
                        ) : null}
                        {i.syn || i.synbis || i.syntris != null ? (
                          <p>
                            {" "}
                            <span className={classes.posTagStyle}>
                              Amegdawal:
                            </span>{" "}
                            <strong className={classes.PartOfSpeechStyle}>
                              <span style={{ color: "red" }}>{i.syn}</span>{" "}
                              <span style={{ color: "green" }}>{i.synbis}</span>{" "}
                              <span style={{ color: "blue" }}>{i.syntris}</span>
                            </strong>
                          </p>
                        ) : null}
                      </p>
                    ))}
                </Typography>
              )}
            </Typography>
            {word &&
              word.sense.map((meaning) => (
                <ul>
                  {meaning.translation.map((quotes) => (
                    <Typography>
                      <li className={classes.quoteStyle}>{quotes.quote}</li>
                      <div className={classes.examplesStyles}>
                        {quotes.examples != null ? (
                          <p>
                            {quotes.examples.map((i) => (
                              <p>
                                - {i.example + ": "}
                                <i className={classes.transExampleStyle}>
                                  {i.transExample}
                                </i>
                              </p>
                            ))}
                          </p>
                        ) : null}
                      </div>
                    </Typography>
                  ))}

                  <Grid item xs={12}>
                    <CardContent>
                      {meaning.expression != null ? (
                        <Typography>
                          <i className="fa fa-angle-double-right"></i>{" "}
                          <span className={classes.expressionsStyle}>
                            {" "}
                            Expressions: Tinfaliyin
                          </span>
                          {meaning.expression.map((quotes) => (
                            <div>
                              <li className={classes.examplesStyles}>
                                {" "}
                                {quotes.quote}: <br />
                                <i
                                  style={{
                                    marginLeft: "50px",
                                    color: "#c12d30",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {quotes.transExample}
                                </i>
                              </li>
                            </div>
                          ))}
                        </Typography>
                      ) : null}
                    </CardContent>
                  </Grid>
                </ul>
              ))}
          </Paper>
        </Grid>
      </Card>
    </Container>
  );
};

export default WordItem;
